import format from "date-fns/format";
import parse from "date-fns/parse";

export const DEFAULT_LANDING_ROUTE = "/"
export const LOGGED_IN_LANDING_ROUTE = "/"
export const LOGIN_PAGE_ROUTE = "/login"

export const APP_NAME = 'Tixies'

export const getFileNameFromUrl = (fileUrl) => {
    return fileUrl.split('/').slice(-1).toString().split("?")[0]
}

export const PAGINATION_LIMIT_OPTIONS = [10, 20, 50, 100];

export const getFormattedDateFromApi = (apiDate) => {
    return apiDate ? format(
        parse(apiDate, 'yyyy-MM-dd', new Date()), 'MMM. d, yyyy'
    ) : '-';
}

// export const CREATE_COLLECTIBLES_CONTRACT_ADDRESS = "0xf157cebd255bbf08d8674db3175be80894bd65dc";
export const CREATE_COLLECTIBLES_CONTRACT_ADDRESS = "0x6237e74d7dd3e44f753dd6aa36f488a7f1a14a62";
export const MINT_NFT_CONTRACT_ADDRESS = "0x10ec443226f9a71b6e312b4997bd397a0128f43d";
