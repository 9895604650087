import {useEffect, useState} from "react";
import axios from "axios";
import {getApiToken, removeApiToken} from "./auth";


const DEFAULT_ERROR_OBJECT = {
    detail: 'Unexpected error occurred. Please try again.'
}

const baseURLProduction = "https://crypto-ticket-excha-30450.botics.co";
const baseURLStaging = "https://tixies-30450-staging.botics.co";

const main_api = axios.create({baseURL: baseURLStaging});

const errorResponseInterceptor = (error) => {
    if (error.response) {
        if (error.response.status === 401) {
            removeApiToken();
            window.location.reload();
        } else if (!error.response.data || typeof error.response.data === 'string') {
            // assign default error object
            error.response.data = DEFAULT_ERROR_OBJECT;
        }
    } else {
        // assign default error object
        error.response = {
            data: DEFAULT_ERROR_OBJECT
        };
    }
    return Promise.reject(error);
}

main_api.interceptors.request.use(config => {
    const api_token = getApiToken();
    if (api_token) {
        config.headers.Authorization = `Token ${api_token}`;
    } else {
        delete config.headers.Authorization;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

main_api.interceptors.response.use(response => {
    return response;
}, errorResponseInterceptor);

const useMainApi = (
    url, method = 'get', formData = null, requestParams = {},
    validateJsonResponse = true, timeout = 30000
) => {
    const [data, setData] = useState(null);
    const [errors, setErrors] = useState({});
    const [processing, setProcessing] = useState(false);
    const [params, setParams] = useState(requestParams);

    useEffect(() => {
        let unmounted = false;
        let source = axios.CancelToken.source();
        setProcessing(true);
        setData(null);
        setErrors({});
        main_api({
            baseURL: baseURLStaging,
            url: url,
            method: method,
            data: formData,
            params: params,
            timeout: timeout,
            cancelToken: source.token,
        }).then(response => {
            if (!unmounted) {
                const contentType = response.headers["content-type"];
                if (!validateJsonResponse || (
                    contentType && contentType.indexOf("application/json") !== -1
                )) {
                    setData(response.data);
                } else {
                    setErrors(DEFAULT_ERROR_OBJECT);
                }
            }
        }).catch(e => {
            if (!unmounted) {
                setErrors(e?.response?.data);
                if (axios.isCancel(e)) {
                    console.error(`request cancelled:${e.message}`);
                } else {
                    console.error("another error happened:" + e.message);
                }
            }
        }).then(() => {
            if (!unmounted) {
                setProcessing(false);
            }
        });
        return function () {
            unmounted = true;
            source.cancel("Cancelling in cleanup");
        };
    }, [url, method, formData, params, validateJsonResponse, timeout]);

    const reloadData = () => {
        setParams({...params});
    }

    return {data, processing, errors, setParams, params, reloadData, setData};
}

const useAxiosGet = (url, requestParams = {}) => {
    return useMainApi(url, 'get', null, requestParams);
}

export {main_api, useAxiosGet};
