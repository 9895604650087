import React, { useEffect, useState } from "react"
import LandingPageLayout from "../../../components/app/layouts/LandingPageLayout"
import { Link, useLocation, useParams } from "react-router-dom"
import styles from "./MyCollectibleDetailPage.module.scss"
import { ReactComponent as IcLocation } from "../../../assets/ic-location.svg"
import { ReactComponent as IcChair } from "../../../assets/ic-chair.svg"
import { Badge, Button, Card, Col, Image, Row,Pagination, Accordion } from "react-bootstrap"
import { useAxiosGet } from "../../../utils/axios_helper"
import FullPageOverlayLoader from "../../../components/generic/Loaders/FullPageOverlayLoader"
import ErrorBlock from "../../../components/generic/ValidationMessages/ErrorBlock"
import EventDisplayPicture from "../../../components/app/events/EventDisplayPicture"
import { getFormattedDateFromApi } from "../../../utils/constants"
import HeadTags from "../../../components/generic/HeadTags"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/free-regular-svg-icons"
import ReportNftModal from "../../../components/app/collectibles/ReportNftModal"
import OfferModal from "../../../components/app/collectibles/OfferModal"
import NftCard from "../../../components/app/nft/NftCard"
import FloatingInput from "../../../components/generic/InputFields/FloatingInput"
import openseaClient, { CHAIN } from "../../../utils/opensea_client"
import axios from "axios"

export default function MyCollectibleDetailPage() {
  const location = useLocation();
  const { nft } = location.state || {};  
  

  const processing = false
  const errors = { detail: [] }

  const [nftDetailDate, setNftDetailDate] = useState(null)
  const [nftMetaData, setNftMetaData] = useState(null)

  useEffect(() => {
    const fetchCollectionsNFT = async () => {
      try {
        const response = await openseaClient.get(`/chain/${CHAIN}/contract/${nft.contract}/nfts/${nft.identifier}`);
        setNftDetailDate(response.data.nft);
        
        const metaDataResponse = await axios.get(response.data.nft.metadata_url);
        setNftMetaData(metaDataResponse.data);

        const historyResponse = await openseaClient.get(`/events/chain/${CHAIN}/contract/${nft.contract}/nfts/${nft.identifier}`);
        console.log("historyResponse = ", historyResponse);
        
      } catch (error) {
        console.log("error = ", error);
      }
    }
    fetchCollectionsNFT()
  }, []);

  const data = {
    category: "Quantum Arts",
    title: "Bored Ape",
    creator: "Yuga Labs",
    owner: "Rhyme",
    likes: 352,
    cryptoPrice: "1,800",
    price: "1,500",
    cryptoHighestBid: "2,800",
    highestBid: "2,200",
    image:
      "https://media.licdn.com/dms/image/D4E12AQFLKmSeXoX9wQ/article-cover_image-shrink_720_1280/0/1690376030758?e=2147483647&v=beta&t=dhx_EN1ZW7E3cyzv5Vg24IK5f6URXearMdPXACJkvd8",
    circulerImage:
      "https://img.freepik.com/free-photo/vivid-blurred-colorful-background_58702-2655.jpg?w=996&t=st=1718292526~exp=1718293126~hmac=d4552ba35c570dceb26867cc981043e593c8946f56615c0c593761550b7027e7",
    history: [
      {
        cryptoPrice: 2000,
        price: 1800,
        datetime: "46 minutes ago",
        address: "0x36e2...d3f1",
        image:
          "https://media.licdn.com/dms/image/D4E12AQFLKmSeXoX9wQ/article-cover_image-shrink_720_1280/0/1690376030758?e=2147483647&v=beta&t=dhx_EN1ZW7E3cyzv5Vg24IK5f6URXearMdPXACJkvd8"
      },
      {
        cryptoPrice: 2000,
        price: 1800,
        datetime: "46 minutes ago",
        address: "0x36e2...d3f1",
        image:
          "https://media.licdn.com/dms/image/D4E12AQFLKmSeXoX9wQ/article-cover_image-shrink_720_1280/0/1690376030758?e=2147483647&v=beta&t=dhx_EN1ZW7E3cyzv5Vg24IK5f6URXearMdPXACJkvd8"
      },
      {
        cryptoPrice: 2000,
        price: 1800,
        datetime: "46 minutes ago",
        address: "0x36e2...d3f1",
        image:
          "https://media.licdn.com/dms/image/D4E12AQFLKmSeXoX9wQ/article-cover_image-shrink_720_1280/0/1690376030758?e=2147483647&v=beta&t=dhx_EN1ZW7E3cyzv5Vg24IK5f6URXearMdPXACJkvd8"
      }
    ]
  }


  const category = nftMetaData?.attributes?.find(attr => attr.trait_type === "category")?.value?.name;
  const collection = nftMetaData?.attributes?.find(attr => attr.trait_type === "collection")?.value?.name;
  const externalLink = nftMetaData?.attributes?.find(attr => attr.trait_type === "external_link")?.value;

  return (
    <LandingPageLayout>
       <HeadTags title={`All Events`} />
        {processing && <FullPageOverlayLoader />}
        <div className={`mwc pt-4`}>
          <ErrorBlock errors={errors.detail} />
        </div>
        {nftDetailDate && (
          <div className={`mwc pt-4`}>
            <Row>
              <Col md={6}>
                <div style={{ width: 575 }}>
                  <Image
                    fluid
                    src={nftDetailDate?.image_url}
                    alt={nftDetailDate?.name}
                    className="rounded-3 w-100"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div style={{ maxWidth: "576px" }}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <div className="d-flex align-items-center gap-2">
                                <span className="fw-bold">{nftDetailDate?.collection}</span>
                            </div>
                            <div className={`fs-2 fw-bold color-black mt-3`}>
                                {nftDetailDate?.name}
                            </div>
                        </div>
                        <Button className={`fw-bold color-black mt-3`}>
                            List for Sale
                        </Button>
                    </div>
                    <div className="d-flex gap-4 flex-wrap my-5">
                      {collection && (
                        <div className="d-flex flex-row gap-2">
                          <div className="fw-bold">Collection:</div>
                          <div>{collection}</div>
                        </div>
                      )}
                      {category && (
                        <div className="d-flex flex-row gap-2">
                          <div className="fw-bold">Category:</div>
                          <div>{category}</div>
                        </div>
                      )}
                      {externalLink && (
                        <div className="d-flex flex-row gap-2">
                          <div className="fw-bold">External Link:</div>
                          <div><a href={externalLink} target="_blank" rel="noreferrer">{externalLink}</a></div>
                        </div>
                      )}
                    </div>
                    {nftMetaData?.description && (
                      <div className="d-flex flex-row gap-2">
                        <div className="fw-bold">Description:</div>
                        <div>{nftMetaData?.description}</div>
                      </div>
                    )}
                  <div className="mt-4">
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Price History</Accordion.Header>
                            <Accordion.Body>
                            {data?.history.map((item, index) => (
                                <div key={index} className="d-flex gap-2 mb-2">
                                <Image
                                    fluid
                                    style={{ width: "56px", height: "56px" }}
                                    src={item.image}
                                    className="rounded-circle"
                                />
                                <div
                                    className="d-flex justify-content-between align-items-center"
                                    style={{ flexBasis: "100%" }}
                                >
                                    <div>
                                    <div className="fw-bold">{item.address} listed for</div>
                                    <div className="small fw-bold text-muted">
                                        {item.datetime}
                                    </div>
                                    </div>
                                    <div className="d-flex flex-column align-items-end">
                                        <div className="fw-bold">{item.cryptoPrice} MATIC</div>
                                        <div className="small">${item.price}</div>
                                    </div>
                                </div>
                            </div>
                                ))}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Offers</Accordion.Header>
                            <Accordion.Body>
                              <div className="text-center">No offers yet</div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
    </LandingPageLayout>
  )
}
